import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const SpinerComponent = () => {
  return (
    <div className="spinner-wrapper">
      <Spinner
        className="spinner-border"
        style={{ height: '80px', width: '80px' }}
        animation="border"
        variant="light"
      />
    </div>
  );
};

export default SpinerComponent;
