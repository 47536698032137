import React from 'react';

const CustomInputy = (props) => {
  const { type, name, placeholder, val, onCh, onBl } = props;

  return (
    <div className="form-floating mt-3">
      <input
        type={type}
        className={`form-controll`}
        placeholder={placeholder}
        name={name}
        value={val}
        onChange={onCh}
        onBlur={onBl}
      />
    </div>
  );
};

export default CustomInputy;
